import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Layout from "../components/layout/MainLayout";
import AllBlogs from "../components/routes/Blog/AllBlogs";
import { colors } from "../vars/palette";
import { WmkSeo } from "wmk-seo";
import { twitter, siteTitle, baseUrl } from "../vars/ThemeOptions";

const ErrorWrap = styled(Row)`
  padding: 2rem;
  .col {
    -webkit-flex: initial;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  h1 {
    color: ${colors.hex("secondary")};
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Container>
      <WmkSeo.Meta
        title={"Watermark Agency | Page Not Found (404)"}
        path="/"
        slug={"404"}
        description={
          "Error 404: The page your are looking for doesn't exist at this address."
        }
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
        ogImageUrl={""}
        twitterImageUrl={""}
      />
      <ErrorWrap className="flex-column">
        <Col>
          <h1>NOT FOUND</h1>
        </Col>
        <Col>
          <p>
            You just hit a route that doesn&#39;t exist... the sadness. Were you
            looking for a blog below?
          </p>
        </Col>
        <Col>
          <AllBlogs hideTitle={true} />
        </Col>
      </ErrorWrap>
    </Container>
  </Layout>
);

export default NotFoundPage;
